<script>
import $ from 'jquery'
import UserForm from "./UserForm";
import axios from "axios";
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import Link from "../utils/Link";
import ui from "../utils/ui";


export default {
  name: "SingleUser",
  components: {
    UserForm: UserForm
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  mixins: [UserForm],

  data() {
    return {
      userRequest: {},
      changePassword: {},
      showDiv: false,
      isUsernameValid: true,
      isUsernameFree: true,
      isEmailValid: true,
      isEmailFree: true,
      isPasswordValid: true,
      isPassword2Valid: true
    }
  },

  mounted() {
    if (this.isNewUser()) {
      this.allFieldsEnabled();
      $('#edit').css('display', 'none');
      $('#create').css('display', 'block');
      $('.name').text(this.$t("singleUser.newUser"))
      $('#showChangePassword').css('display', 'none');
      this.userRequest.role = 'USER';
    } else {
      this.getUserRequest().then( () => {
        this.changePassword = {
          userName: this.userRequest.username
        }
      });
    }
  },

  methods: {
    isNewUser() {
       return (this.$props.id === 'new');
    },

    allFieldsEnabled() {
      $('#password').prop('disabled', false);
      $('#user-name').prop('disabled', false);
      $('#first-name').prop('disabled', false);
      $('#last-name').prop('disabled', false);
      $('#role').prop('disabled', false);
      $('#email').prop('disabled', false);
    },

    showChangePasswordForm() {
      $('#edit').css('display', 'none');
      $('.lineBody-r').css('display', 'block');
      $('#oldPassword').css('disabled', true);
      $('#showChangePassword').css('display', 'none')
    },

    closeChangePasswordForm() {
      $('#edit').css('display', 'block');
      $('#showChangePassword').css('display', 'block');
      $('.lineBody-r').css('display', 'none');
      $('#oldPassword').val('');
      $('#newPassword').val('');
      $('#newPasswordConfirm').val('');
    },

    editFields() {
      $('#first-name').prop('disabled', false);
      $('#last-name').prop('disabled', false);
      $('#role').prop('disabled', false);
      $('#email').prop('disabled', false);

      $('#edit').css('display', 'none');
      $('#showChangePassword').css('display', 'none');
      $('#submit-edit').css('display', 'block');
      $('#cancel-edit').css('display', 'block');
    },

    disableInputs() {
      this.isEmailValid = true;
      this.isEmailFree = true;

      $('#first-name').prop('disabled', true);
      $('#last-name').prop('disabled', true);
      $('#role').prop('disabled', true);
      $('#email').prop('disabled', true);

      $('#edit').css('display', 'block');
      $('#showChangePassword').css('display', 'block');
      $('#submit-edit').css('display', 'none');
      $('#cancel-edit').css('display', 'none');
    },

    saveNewPassword() {
      this.isPasswordValid = (this.changePassword.password != null && this.changePassword.password.trim().length > 5);
      this.isPassword2Valid = (this.changePassword.password === this.changePassword.passwordConfirm)

      if (this.isPasswordValid && this.isPassword2Valid) {
        const headers = Link.methods.getHeaders();
        const req = {
          oldPassword: undefined,
          userName: this.userRequest.username,
          password: this.changePassword.password
        }
        axios.put(
            Link.methods.getUserChangePasswordUrl(),
            req,
            {headers}
        ).then((res) => {
          this.createToast(this.$t("userProfile.passwordEditToast"), "success");

          const userId = window.localStorage.getItem("id");
          if (userId != null && res.data.id === userId) {
            let request = {
              username: window.localStorage.getItem('userName'),
              password: this.changePassword.password
            }
            axios.post(
                Link.methods.getAuthenticationUrl(),
                request
            ).then((res) => {
              window.localStorage.setItem('token', `Bearer_` + res.data.token);
              this.closeChangePasswordForm()
            }).catch(() => {
              this.createToast(this.$t("userForm.authErrorToast"), "error");
            });
          } else {
            this.closeChangePasswordForm()
          }
        }).catch((reason) => {
          if (reason.response.status === 403) {
            this.isOldPasswordValid = false;
            this.createToast(this.$t("userProfile.wrongPasswordError"), "error");
          } else {
            this.createToast(this.$t("userProfile.changePasswordError"), "error");
          }
        });
      }
    },

    editRequest() {
      this.isEmailValid = ui.methods.checkEmail(this.userRequest.email);
      if (this.isEmailValid) {
        this.sendEditRequest()
      } else {
        this.createToast(this.$t("registration.enterEmail"), "error");
      }
    },

    cancelRequest() {
      this.getUserRequest().then( () => {
        this.disableInputs();
      });
    },

    createRequest() {
      this.isEmailValid = ui.methods.checkEmail(this.userRequest.email);
      this.isPasswordValid = (this.userRequest.password != null && this.userRequest.password.trim().length > 5);
      this.isUsernameValid = (this.userRequest.username != null && this.userRequest.username.trim().length > 0);

      if (!(this.isUsernameValid && this.isEmailValid && this.isPasswordValid)) {
        return;
      }

      const headers = Link.methods.getHeaders();
      axios.post(
          Link.methods.getNewUserUrl(),
          this.userRequest,
          {headers}
      ).then(() => {
        this.isEmailFree = true;
        this.isUsernameValid = true;
        this.createToast(this.$t("singleUser.createToast", [this.userRequest.username]));
        this.goBack()
      }).catch((reason) => {
        this.isEmailFree = true;
        this.isUsernameFree = true;
        if (reason.response.status === 406) {
          // username already exists
          this.isUsernameFree = false;
          this.isUsernameValid = false;
          this.createToast(this.$t("registration.usernameAlreadyExistsToast", [this.userRequest.username]), "error");
        } else if (reason.response.status === 409) {
          // email already exists
          this.isEmailFree = false;
          this.isEmailValid = false;
          this.createToast(this.$t("registration.emailAlreadyExistsToast", [this.userRequest.email]), "error");
        } else {
          // other errors
          this.isUsernameValid = false;
          this.createToast(this.$t("singleUser.userNotCreated"), "error");
        }
      });
    },

    getUserRequest() {
      const headers = Link.methods.getHeaders();
      return axios.get(
          Link.methods.getUserByIdUrl(this.id),
          {headers}
      ).then(res => {
          this.userRequest = res.data;
      }).catch(() =>
          this.createToast(this.$t("singleUser.userNotFound"), "error")
      );
    },

    sendEditRequest() {
      const headers = Link.methods.getHeaders();
      return axios.put(
          Link.methods.getUserUpdateByIdUrl(),
          this.userRequest,
          {headers}
      ).then((res) => {
          this.changeRole(this.userRequest.id).then( () => {
            const userId = window.localStorage.getItem("id");
            if (userId != null && res.data.id === userId) {
              this.$emit("updateUserInfo");
            }
            this.disableInputs();
            this.createToast(this.$t("singleUser.userEditToast"), "success");
            this.userRequest = res.data;
            //return this.getUserRequest();
          });
      }).catch((reason) => {
        if (reason.response.status === 409) {
          // email already exists
          this.isEmailFree = false;
          this.isEmailValid = false;
          this.createToast(this.$t("userForm.emailAlreadyExistsToast", [this.userRequest.email]), "error");
        } else {
          this.createToast(this.$t("userProfile.profileNotEditToast"), "error")
        }
      });
    },

    changeRole(id) {
      const headers = Link.methods.getHeaders()
      return axios.put(
          Link.methods.getRoleUpdateUrl(id, this.userRequest.role),
          {},
          {headers}
      ).catch(() =>
          this.createToast(this.$t("singleUser.roleNotChanged"), "error")
      )
    },

    goBack() {
      this.$router.back();
    },

    showOldPassword() {
      let raw = document.getElementById("oldPassword");
      if (raw.type === "password") {
        raw.type = "text";
      } else {
        raw.type = "password";
      }
    },

    showPassword() {
      let raw = document.getElementById("password");
      if (raw.type === "password") {
        raw.type = "text";
      } else {
        raw.type = "password";
      }
    },

    showNewPassword() {
      let raw = document.getElementById("newPassword");
      if (raw.type === "password") {
        raw.type = "text";
      } else {
        raw.type = "password";
      }
    },

    showNewPassword2() {
      let raw = document.getElementById("newPasswordConfirm");
      if (raw.type === "password") {
        raw.type = "text";
      } else {
        raw.type = "password";
      }
    },

    createToast(msg) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: "success",
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    }
  }
}

</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import '../../../public/materialize-src/sass/materialize.scss';
@import "../../../public/styles/singleUser";
@import "../../../public/styles/hints";

</style>
